<template>
    <v-autocomplete v-on="$listeners"
                    :outlined="outlined"
                    :value="value"
                    :type="type"
                    :label="label"
                    :placeholder="placeholder"
                    :persistent-placeholder="persistentPlaceholder"
                    :hint="hint"
                    :hide-details="hideDetails === undefined ? !hint : hideDetails"
                    :persistent-hint="persistentHint"
                    :clearable="clearable"
                    :counter="counter"
                    :readonly="readonly"
                    :disabled="disabled"
                    :loading="loading"
                    :full-width="fullWidth"
                    :rules="rules"
                    :prepend-icon="prependIcon"
                    :prepend-inner-icon="prependInnerIcon"
                    :append-icon="appendIcon"
                    :items="items"
                    :item-text="itemText"
                    :item-value="itemValue"
                    :return-object="returnObject === undefined ? !itemValue : returnObject"
                    :multiple="multiple"
                    :search-input.sync="searchInput"
                    :no-filter="noFilter"
                    @click:append="$emit('appendClick', $event)"
                    @focus.once="$emit('firstFocus', $event)"
    >

        <template #item="{ item }">
            <slot name="item" :item="item">{{ !itemText ? item : item[itemText] }}</slot>
        </template>

        <template #selection="{ item, index }">
            <slot name="selection" :item="item" :index="index">{{ !itemText ? item : item[itemText] }}</slot>
        </template>

    </v-autocomplete>
</template>

<script>
    export default {
        name: 'BaseAutocompleteField',
        props: {
            outlined: {type: Boolean, default: true},
            value: [String, Number, Boolean, Object, Array],
            type: String,
            label: String,
            placeholder: String,
            persistentPlaceholder: {type: Boolean, default: true},
            hint: String,
            hideDetails: {type: Boolean, default: undefined},
            persistentHint: {type: Boolean, default: true},
            clearable: Boolean,
            counter: {type: Boolean, default: true},
            readonly: Boolean,
            disabled: Boolean,
            loading: Boolean,
            fullWidth: Boolean,
            rules: Array,
            prependIcon: String,
            prependInnerIcon: String,
            appendIcon: String,
            items: Array,
            itemText: [String, Array, Function],
            itemValue: [String, Array, Function],
            returnObject: {type: Boolean, default: undefined},
            multiple: Boolean,
            noFilter: Boolean
        },
        data: () => ({
            searchInput: null
        }),
        watch: {
            searchInput: function () {
                this.$emit('filterSearch', this.searchInput);
            }
        }
    }
</script>

<style scoped>

</style>
