<template>
    <base-autocomplete-field v-on="$listeners"
                             :outlined="outlined"
                             :value="value"
                             :type="type"
                             :label="label"
                             :placeholder="placeholder"
                             :persistent-placeholder="persistentPlaceholder"
                             :hint="hint"
                             :hide-details="hideDetails === undefined ? !hint : hideDetails"
                             :persistent-hint="persistentHint"
                             :clearable="clearable"
                             :counter="counter"
                             :readonly="readonly"
                             :disabled="!disableDisabled && (disabled || isGlobalLoading)"
                             :loading="!disableLoading && (loading || isGlobalLoading)"
                             :full-width="fullWidth"
                             :rules="rules"
                             :prepend-icon="prependIcon"
                             :prepend-inner-icon="prependInnerIcon"
                             :append-icon="appendIcon"
                             :items="items"
                             :item-text="itemText"
                             :item-value="itemValue"
                             :return-object="returnObject === undefined ? !itemValue : returnObject"
                             :multiple="multiple"
                             no-filter
                             @firstFocus="$emit('firstFocus', $event)"
                             @filterSearch="filterSearch = $event"
    >

        <template #item="{ item }">
            <slot name="item" :item="item">{{ !itemText ? item : item[itemText] }}</slot>
        </template>

        <template #selection="{ item, index }">
            <slot name="selection" :item="item" :index="index">{{ !itemText ? item : item[itemText] }}</slot>
        </template>

    </base-autocomplete-field>
</template>

<script>
    import BaseAutocompleteField from '@/components/aaaGenerics/form/BaseAutocompleteField';

    export default {
        name: 'BaseFormFieldApiAutocomplete',
        components: {BaseAutocompleteField},
        props: {
            //AutoCompleteField
            outlined: {type: Boolean, default: true},
            value: [String, Number, Boolean, Object, Array],
            type: String,
            label: String,
            placeholder: String,
            persistentPlaceholder: {type: Boolean, default: true},
            hint: String,
            hideDetails: {type: Boolean, default: undefined},
            persistentHint: {type: Boolean, default: true},
            clearable: {type: Boolean, default: true},
            counter: {type: Boolean, default: true},
            readonly: Boolean,
            disabled: Boolean,
            loading: Boolean,
            fullWidth: Boolean,
            rules: Array,
            prependIcon: String,
            prependInnerIcon: String,
            appendIcon: String,
            itemText: [String, Array, Function],
            itemValue: [String, Array, Function],
            returnObject: {type: Boolean, default: undefined},
            multiple: Boolean,
            //ApiAutocompleteField
            modelsName: String,
            path: String,
            autoStart: Boolean,
            filterStart: String,
            disableDisabled: Boolean,
            disableLoading: Boolean
        },
        data: () => ({
            isLoading: false,
            apiTimer: null,
            filterSearch: null,
            isReadySearch: true
        }),
        computed: {
            isGlobalLoading: function () {
                return this.$store.state.servicesModule.isGlobalLoading;
            },
            items: function () {
                return this.$store.state.apiModule[this.modelsName.toLowerCase()];
            }
        },
        watch: {
            value: function () {
                this.isReadySearch = false;
            },
            filterSearch: function () {
                if (this.isReadySearch && this.filterSearch) {
                    window.clearTimeout(this.apiTimer);
                    this.isLoading = true;

                    this.apiTimer = window.setTimeout(() => {
                        window.clearTimeout(this.apiTimer);
                        this.isLoading = false;
                        this.loadModel(this.filterSearch);
                    }, 1000);
                }
                this.isReadySearch = true;
            }
        },
        methods: {
            loadModel(filter) {
                function doRequest(_this) {
                    if (!_this.isGlobalLoading) {
                        window.clearInterval(_this.apiTimer);

                        //GET del modello
                        _this.$store.dispatch('defaultRequest', {
                            api: 'GET_BASE',
                            paylod: {
                                modelsName: _this.modelsName.toLowerCase(),
                                path: _this.path,
                                options: {page: 1, itemsPerPage: 50, filter, isExact: true}
                            },
                            doResponse: response => {

                                if (_this.value) {

                                    //Cerco tra i risultati il record già selezionato nella combobox
                                    let value = response.items.find(i => i.ID_REC === _this.value.ID_REC);

                                    //Se nei risultati NON trovo lo stesso selezionato, lo aggiungo per primo
                                    if (!value) {

                                        response.items.unshift(_this.value);

                                        _this.$store.commit('SET_' + _this.modelsName.toUpperCase(), response.items);

                                    }

                                }

                                _this.$emit('finish', response);

                            },
                            doClose: () => _this.isLoading = false
                        });
                    }
                }

                this.isLoading = true;
                this.apiTimer = window.setInterval(() => doRequest(this), 1000);
                doRequest(this);
            }
        },
        created() {
            if (this.autoStart) {
                this.isReadySearch = false;
                this.loadModel(this.value[this.filterStart ? this.filterStart : this.itemText]);
            }
        },
        beforeDestroy() {
            window.clearInterval(this.apiTimer);
            window.clearTimeout(this.apiTimer);
            this.$store.commit('SET_' + this.modelsName.toUpperCase(), []);
        }
    }
</script>

<style scoped>

</style>
